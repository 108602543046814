// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("what-input");
require("channels");
require("foundation-sites");
import MotionUI from "motion-ui";

var QRCode = require('qrcode');

//import '../stylesheets/application';

document.addEventListener("turbolinks:load", function(){
    $(document).foundation();

    var qrModal = document.querySelector("#qrModal");
    var qrCanvas = document.querySelector("#qrCanvas");
    QRCode.toCanvas(qrCanvas, location.href ,{scale: 6}, function (error) {
	if (error) console.error(error);
    });

    $(qrModal).on("closeme.zf.reveal", function(){
	MotionUI.animateIn("#qrModal", "slide-up fast");
    });

    $(qrModal).on("closed", function(){
	alert('seci');
    });
    
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
